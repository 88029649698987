import React, { ReactNode } from 'react';
import { FdjAttachmentType } from '../fdjDemandTypes';

export const fdjAttachmentTooltipContents: Partial<Record<FdjAttachmentType, ReactNode>> = {
  [FdjAttachmentType.PieceIdentite]: (
    <>
      Une copie recto/verso de la carte nationale d'identité en cours de validité ou du titre de séjour en cours de
      validité ou les pages 3 et 4 du passeport en cours de validité
    </>
  ),
  [FdjAttachmentType.AvisImposition]: <>Une copie de la 1ère et 2ème page du dernier avis d'imposition</>,
  [FdjAttachmentType.LivretFamille]: (
    <>
      <p>Si la personne possède un livret de famille, une copie des pages principales du livret de famille.</p>
      <p>À défaut du livret de famille, il est possible de fournir l'acte de naissance du candidat.</p>
    </>
  ),
  [FdjAttachmentType.ActeAchat]: (
    <>
      <p>Dans notre cas, il s'agit de fournir une copie de l'acte de vente enregistré auprès des services fiscaux.</p>
      <p>
        En cas de non disponibilité de celui-ci, vous pouvez fournir la copie intégrale de la promesse de vente signée
        sous seing privé ou chez le notaire.
      </p>
    </>
  ),
  [FdjAttachmentType.Adjudication]: (
    <>
      Dans le cas d'une acquisition par adjudication judiciaire, fournir une copie du jugement du tribunal et/ou une
      copie de l'acte d'acquisition
    </>
  ),
  [FdjAttachmentType.ContratLocationGerance]: (
    <>
      En cas de location gérance de l'exploitant, fournir une copie du contrat de location. Ou une convention
      d'occupation des locaux par l'exploitant provenant d'une société mixte ou d'une collectivité
    </>
  ),
  [FdjAttachmentType.AccordPret]: (
    <>
      <p>
        La copie de l'accord de prêt bancaire est obligatoire s'il y a un prêt bancaire et si le fonds de commerce est
        acquis depuis moins de 3 ans. Cette dernière information est disponible sur l'extrait KBIS sous la dénomination
        « Date de commencement de l'activité ».
      </p>
      <p>Elle permet de répondre, en partie, à la justification des apports personnels.</p>
      <p>Si l'accord de prêt n'est pas encore signé, vous pouvez fournir un accord de principe</p>
    </>
  ),
  [FdjAttachmentType.JustificationApports]: (
    <>
      La justification des apports personnels peut se faire par tout document ayant permis d’alimenter le compte
      bancaire (acte de vente notarial, acte de succession, relevé CARPA…), en supplément ou en lieu et place du prêt
      bancaire. On est dispensé de ce document si le fonds de commerce est acquis depuis plus de 3 ans.
    </>
  ),
  [FdjAttachmentType.PlanFinancement]: <>L'intégralité des pages du plan de financement doit être fournie</>,
  [FdjAttachmentType.ExtraitK]: (
    <>
      <p>
        L'extrait K ou KBIS daté de moins de 3 mois. Si l'extrait KBIS n'est pas encore disponible veuillez fournir :
      </p>
      <ul>
        <li>pour une société : le projet de statut de la société.</li>
        <li>pour une entreprise en nom propre : le récépissé d'inscription à la chambre de commerce.</li>
      </ul>
      <p>L'extrait KBIS sera demandé par la suite.</p>
    </>
  ),
  [FdjAttachmentType.ExtraitKbis]: (
    <>
      <p>Fournir l'extrait K ou KBIS daté de moins de 3 mois. S'il n'est pas encore disponible, veuillez fournir :</p>
      <ul>
        <li>Pour une société : le projet de statut de la société.</li>
        <li>
          Pour une entreprise en nom propre : un courrier de l’avocat ou du notaire certifiant qu’il s‘occupe de
          l’enregistrement de la société.
        </li>
      </ul>
      <p>L'extrait KBIS sera demandé par la suite.</p>
    </>
  ),
  [FdjAttachmentType.StatutsSociete]: (
    <>
      Fournir une copie des statuts de la société et une répartition du capital si ce n'est pas indiqué (cas des SA ou
      SAS) ou si la répartition n'est plus la même que dans les statuts initiaux. Si les statuts ne sont pas encore
      disponibles, fournir le projet de statuts
    </>
  ),
  [FdjAttachmentType.LiasseFiscale]: (
    <>
      Si la société a déjà eu un exercice sur l'année précédente, fournir une copie de la liasse fiscale du dernier
      exercice.
    </>
  ),
  [FdjAttachmentType.JustificationFondsFinancementLocationGerance]: (
    <>
      Pour le cas de la location gérance il faudra pour l'exploitant, justifier de la provenance des fonds servant au
      financement de la location gérance. Comme dans le cas d'une justification des apports personnels, la preuve peut
      se faire par tout document justifiant de la provenance des fonds (extrait de compte bancaire, déclaration d'argent
      liquide, relevé de rachat de contrats d'assurance, vente héritage…)
    </>
  ),
  [FdjAttachmentType.ContratAchatExclusifBoissons]: (
    <>Fournir une copie du contrat exclusif de boissons ou du prêt brasseur, si le PDV est lié à un brasseur.</>
  ),
  [FdjAttachmentType.NonChangementOrganesDirection]: (
    <>
      Lettre déclarative à l'initiative du client pro qui s'engage sur l'honneur qu'aucune modification des organes de
      direction n'a eu lieu depuis l'obtention de l'agrément FDJ
    </>
  ),
  [FdjAttachmentType.AutresPieces]: <>Toutes pièces susceptibles d'intéresser le SCCJ</>,
};
