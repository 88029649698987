import React from 'react';
import FdjDemandsFilters from './filters/FdjDemandsFilters';
import {
  extractPaginationAndSortCommand,
  formValuesToQueryString,
} from '@eogile/agrements-common/src/utils/tableUtils';
import FdjDemandsTableCard from './table/FdjDemandsTableCard';
import { useFdjDemandsListLoader } from './useFdjDemandsListLoader';

export const FdjDemandsList = () => {
  const { filterValues, apiParams, data, requestParams, onPaginationOrSortChange } =
    useFdjDemandsListLoader('DEMANDS_LIST');
  return (
    <>
      <FdjDemandsFilters
        initialValues={filterValues}
        formValuesToQueryString={formValuesToQueryString(requestParams)}
      />
      {data && (
        <FdjDemandsTableCard
          apiParams={apiParams}
          data={data}
          command={extractPaginationAndSortCommand(requestParams)}
          onCommandChange={onPaginationOrSortChange}
        />
      )}
    </>
  );
};
