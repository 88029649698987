import { FdjDemand } from '../../../fdjDemandTypes';
import { FdjVerdictInputCommand, FdjVerdictInputFormValues } from './fdjVerdictInputFormTypes';
import { CancellationReason, RejectionReason, VerdictType } from '@eogile/agrements-common/src/demand/demandTypes';
import {
  hasAskedCancellation,
  toStandardCancelledVerdictInputCommand,
  toStandardCancelledVerdictInputFormValues,
  toStandardCompleteCaseVerdictInputCommand,
  toStandardCompleteCaseVerdictInputFormValues,
  toStandardGrantedVerdictInputCommand,
  toStandardGrantedVerdictInputFormValues,
  toStandardRejectedVerdictInputCommand,
  toStandardRejectedVerdictInputFormValues,
} from '@eogile/agrements-common/src/demand/components/actions/verdictinput/verdictInputFormUtils';

export const buildInitialFdjVerdictInputFormValues = (demand: FdjDemand): FdjVerdictInputFormValues => {
  if (hasAskedCancellation(demand)) {
    return { verdictType: VerdictType.CANCELLED };
  }
  switch (demand.verdict?.status) {
    case VerdictType.GRANTED:
      return toStandardGrantedVerdictInputFormValues();
    case VerdictType.COMPLETE_CASE:
      return toStandardCompleteCaseVerdictInputFormValues();
    case VerdictType.CANCELLED:
      return {
        ...toStandardCancelledVerdictInputFormValues(demand),
        cancellationReason: demand.verdict.cancellationReason,
      };
    case VerdictType.REJECTED:
      return toStandardRejectedVerdictInputFormValues(demand);
    default:
      return { verdictType: undefined };
  }
};

export const buildFdjVerdictInputCommand = (formValues: FdjVerdictInputFormValues): FdjVerdictInputCommand => {
  switch (formValues.verdictType) {
    case VerdictType.GRANTED:
      return toStandardGrantedVerdictInputCommand();
    case VerdictType.COMPLETE_CASE:
      return toStandardCompleteCaseVerdictInputCommand();
    case VerdictType.CANCELLED:
      return {
        ...toStandardCancelledVerdictInputCommand(formValues),
        cancellationReason: formValues.cancellationReason,
      };
    case VerdictType.REJECTED:
      return toStandardRejectedVerdictInputCommand(formValues);
    default:
      throw new Error('invalid verdict type for command creation');
  }
};

export const fdjActiveRejectionReasons: readonly RejectionReason[] = [
  RejectionReason.NO_BUDGET_JUSTIFICATION,
  RejectionReason.OTHER,
];

export const fdjMinistryCancellationReasons: readonly CancellationReason[] = [
  CancellationReason.INCOMPLETE_CASE,
  CancellationReason.DID_NOT_DEFER,
  CancellationReason.MINISTRY_OTHER,
];
