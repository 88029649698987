import { useFdjAuthenticatedCurrentUser } from '../../security/hooks';
import { Option } from '@eogile/agrements-common/src/form/components/SelectAdapter';
import { useCallback } from 'react';
import { getAllAgenciesOrderedByCode } from '@eogile/agrements-common/src/utils/agencyUtils';
import { FormMode, useFormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import { usePromiseLoader } from '@eogile/agrements-common/src/hooks/usePromiseLoader';
import { FdjAgency } from '../../common-types/direction/fdjDirectionTypes';
import { FdjDemand } from '../fdjDemandTypes';
import { useField } from 'react-final-form';
import { currentUserAgency } from '../../user/fdjUserUtils';
import { computeAgencyName } from '../fdjDemandUtils';

export const useFdjAgencyOptionsFormHelper = (existingDemand: FdjDemand | undefined) => {
  const formMode = useFormMode();
  const { userDetails } = useFdjAuthenticatedCurrentUser();
  const agenciesLoader: () => Promise<FdjAgency[]> = useCallback(async () => {
    let agencies: FdjAgency[];
    if (formMode === FormMode.READ || formMode === FormMode.EDIT) {
      agencies = [existingDemand!.agency];
    } else if (Boolean(currentUserAgency(userDetails))) {
      agencies = [currentUserAgency(userDetails)!];
    } else {
      agencies = await getAllAgenciesOrderedByCode();
    }
    return agencies;
  }, [formMode, existingDemand, userDetails]);
  const { data: agencies } = usePromiseLoader(agenciesLoader);
  const agencyOptions: Option<string>[] = (agencies ?? []).map((agency) => ({
    id: agency.code,
    name: computeAgencyName(agency),
  }));
  const selectedAgencyCode = useField('agencyCode').input.value;
  const regionalDirectionOfSelectedAgency = (agencies ?? []).find(
    (a) => a.code === selectedAgencyCode,
  )?.regionalDirection;
  return { agencyOptions: agencyOptions, regionalDirectionOfSelectedAgency };
};
