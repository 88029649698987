import { SectionCard } from '@eogile/agrements-common/src/components/cards/SectionCard';
import { useIsLightDemandFormHelper } from '@eogile/agrements-common/src/demand/hooks/useIsLightDemandFormHelper';
import { FormGridItem } from '@eogile/agrements-common/src/components/grid/FormGridItem';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import { EnumSelectAdapter } from '@eogile/agrements-common/src/form/components/EnumSelectAdapter';
import { OperatingMode } from '@eogile/agrements-common/src/demand/demandTypes';
import { operatingModeLabels } from '@eogile/agrements-common/src/demand/demandUtils';
import { DatePickerAdapter } from '@eogile/agrements-common/src/form/components/DatePickerAdapter';
import { DemandOutletAddress } from '@eogile/agrements-common/src/demand/components/outlet/DemandOutletAddress';
import { DemandOutletPhoneNumbers } from '@eogile/agrements-common/src/demand/components/outlet/DemandOutletPhoneNumbers';
import { outletSectionTitle } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { FormGridContainer } from '@eogile/agrements-common/src/components/grid/FormGridContainer';
import { FormGridContainersStack } from '@eogile/agrements-common/src/components/grid/FormGridContainersStack';
import { FdjDemandFormTooltipContents } from './FdjDemandFormTooltipContents';
import { useExistingAttachmentsRemovalWarningDisplayHelper } from '@eogile/agrements-common/src/demand/components/ExistingAttachmentsRemovalWarningFieldsProvider';

export const FdjDemandOutletSection = () => {
  const isLightDemand = useIsLightDemandFormHelper();
  const { getExistingAttachmentsRemovalWarningIfNeeded } = useExistingAttachmentsRemovalWarningDisplayHelper();

  return (
    <SectionCard title={outletSectionTitle}>
      <FormGridContainersStack>
        <FormGridContainer>
          <FormGridItem>
            <TextFieldAdapter
              name="outlet.name"
              label="Nom du PDV"
              required
              cssUppercase
              infoTooltipContent={<FdjDemandFormTooltipContents.OutletName />}
            />
          </FormGridItem>
          {!isLightDemand && (
            <>
              <FormGridItem>
                <EnumSelectAdapter
                  name="outlet.operatingMode"
                  label="Modalité d'exploitation"
                  required
                  options={Object.values(OperatingMode)}
                  optionName={(opt) => operatingModeLabels[opt]}
                  infoTooltipContent={<FdjDemandFormTooltipContents.OutletOperatingMode />}
                  warningText={getExistingAttachmentsRemovalWarningIfNeeded('operatingModeChange')}
                />
              </FormGridItem>
              <FormGridItem>
                <DatePickerAdapter
                  name="outlet.acquisitionDate"
                  label="Date d'acquisition"
                  required
                  infoTooltipContent={<FdjDemandFormTooltipContents.OutletAcquisitionDate />}
                  warningText={getExistingAttachmentsRemovalWarningIfNeeded('acquisitionDateChange')}
                />
              </FormGridItem>
            </>
          )}
        </FormGridContainer>
        <DemandOutletAddress infoTooltips={{ address1: <FdjDemandFormTooltipContents.OutletAddress /> }} />
        {!isLightDemand && <DemandOutletPhoneNumbers />}
      </FormGridContainersStack>
    </SectionCard>
  );
};
