import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { FdjSimpleDemandView } from '../../fdjDemandListTypes';
import TableCell from '@eogile/agrements-common/src/components/table/TableCell';
import { CurrentUserDetails } from '@eogile/agrements-common/src/security/types';
import { Badge } from '@mui/material';

type FdjDemandCommentsCellProps = {
  currentUserDetails: CurrentUserDetails;
  demandView: FdjSimpleDemandView;
};

function FdjDemandCommentsCell({ currentUserDetails, demandView }: FdjDemandCommentsCellProps) {
  if (demandView.nbComments === 0) {
    return <TableCell />;
  }

  const nb = demandView.nbUnreadComments[currentUserDetails.company];
  const Icon = <ChatBubbleOutlineIcon color="action" fontSize="small" sx={{ verticalAlign: 'middle' }} />;

  return (
    <TableCell>
      {nb > 0 && (
        <Badge badgeContent={nb} color="primary" variant="dot">
          {Icon}
        </Badge>
      )}
      {nb === 0 && Icon}
    </TableCell>
  );
}

export default FdjDemandCommentsCell;
