import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FdjSimpleDemandView } from '../demand/fdjDemandListTypes';
import { GedRequestStatus, GedRequestTraceView } from './gedTypes';
import TableCell from '@eogile/agrements-common/src/components/table/TableCell';
import { formatZonedDateTimeForDisplay } from '@eogile/agrements-common/src/utils/dateUtils';
import dayjs from 'dayjs';
import { gedRequestStatusLabels } from './gedUtils';
import { usePromiseLoader } from '@eogile/agrements-common/src/hooks/usePromiseLoader';
import { useCallback } from 'react';
import ApiHelper from '@eogile/agrements-common/src/api/apiHelper';
import SecondaryActionButton from '@eogile/agrements-common/src/components/buttons/SecondaryActionButton';
import useSnackbar from '@eogile/agrements-common/src/snackbar/useSnackbar';
import { Severity } from '@eogile/agrements-common/src/utils/severityUtils';

type GedRequestTraceDetailsDialogProps = {
  demand: FdjSimpleDemandView | null;
  onClose: () => void;
};

export const GedRequestTraceDetailsDialog = ({ demand, onClose }: GedRequestTraceDetailsDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, inError } = usePromiseLoader<readonly GedRequestTraceView[]>(
    useCallback(async () => {
      if (!demand) {
        return [];
      }
      return ApiHelper.get(`/api/ged/demands/${demand.id}`);
    }, [demand]),
  );
  const canResend =
    demand &&
    demand.hasSignedVerdictFile &&
    (!demand.gedRequestStatus || demand.gedRequestStatus === GedRequestStatus.ERROR);

  const resend = async () => {
    try {
      await ApiHelper.post(`/api/ged/demands/${demand!.id}`, undefined);
      enqueueSnackbar({
        content:
          'Demande envoyée avec succès. Le résultat du traitement peut mettre quelques minutes à être disponible.',
        severity: Severity.INFO,
      });
    } catch (e) {
      enqueueSnackbar({
        content: 'Une erreur est survenue.',
        severity: Severity.ERROR,
      });
    }
  };

  return (
    <Dialog fullWidth open={Boolean(demand)} onClose={onClose}>
      {demand && (
        <>
          <DialogTitle>Détails des envois pour la demande {demand.chronoNumber}</DialogTitle>
          <DialogContent>
            {inError && (
              <Typography color="error.main">Une erreur est survenue pendant le chargement des données.</Typography>
            )}
            {data &&
              (data.length > 0 ? (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date et heure</TableCell>
                        <TableCell>Résultat</TableCell>
                        <TableCell>Détail</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((trace) => (
                        <TableRow key={trace.id}>
                          <TableCell>{formatZonedDateTimeForDisplay(dayjs(trace.sentDateTime))}</TableCell>
                          <TableCell>{gedRequestStatusLabels[trace.status]}</TableCell>
                          <TableCell breakWord sx={{ width: '50%' }}>
                            {trace.status === GedRequestStatus.ERROR && (
                              <>
                                {trace.statusCode && <Typography>Code statut HTTP : {trace.statusCode}</Typography>}
                                {trace.errorDetail && (
                                  <>
                                    <Typography>Détail :</Typography>
                                    <Typography>{trace.errorDetail}</Typography>
                                  </>
                                )}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <>
                  <Typography>Aucun envoi pour la demande.</Typography>
                  {!demand.hasSignedVerdictFile && (
                    <Typography>La demande n'a pas d'avis signé enregistré dans la base.</Typography>
                  )}
                </>
              ))}
          </DialogContent>
          {canResend && (
            <DialogActions>
              <SecondaryActionButton label="Demander le renvoi" action={resend} />
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};
