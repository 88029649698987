import { CanDoDemandActionPostFilter } from '@eogile/agrements-common/src/demand/components/actions/demandActionTypes';
import { CurrentFdjApplicationUserDetails } from '../security/types';
import { currentUserAgency } from '../user/fdjUserUtils';
import { FdjDemand } from './fdjDemandTypes';

export const fdjCanDoDemandActionPostFilter: CanDoDemandActionPostFilter = (actionType, ctx) => {
  const castedUserDetails = ctx.userDetails as CurrentFdjApplicationUserDetails;
  if (Boolean(currentUserAgency(castedUserDetails))) {
    return !Boolean(ctx.demand) || (ctx.demand as FdjDemand).agency.code === currentUserAgency(castedUserDetails)!.code;
  }
  return true;
};
