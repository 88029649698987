import { FdjDemand } from '../fdjDemandTypes';
import { FormGridContainer } from '@eogile/agrements-common/src/components/grid/FormGridContainer';
import { FormGridItem } from '@eogile/agrements-common/src/components/grid/FormGridItem';
import ReadOnlyField from '@eogile/agrements-common/src/form/components/ReadOnlyField';
import { GedRequestStatus } from '../../ged/gedTypes';
import { formatDateTimeForFrenchDisplay } from '@eogile/agrements-common/src/utils/dateUtils';
import { demandIsInFinalState } from '@eogile/agrements-common/src/demand/demandUtils';
import { useAuthenticatedCurrentUser } from '@eogile/agrements-common/src/security/CurrentUserContext';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';

export const FdjDemandGedStatusItem = ({ demand }: { demand: FdjDemand }) => {
  const {
    userDetails: { company },
  } = useAuthenticatedCurrentUser();
  if (company !== UserCompany.FDJ || !demandIsInFinalState(demand)) {
    return null;
  }

  const valueField = () => {
    if (!demand.gedSignedVerdictFileState) {
      return 'Non envoyé';
    } else {
      const sentDateTime = formatDateTimeForFrenchDisplay(demand.gedSignedVerdictFileState.sentDateTime);
      return demand.gedSignedVerdictFileState.status === GedRequestStatus.OK
        ? `Envoyé avec succès le${sentDateTime}`
        : `Échec de l'envoi le${sentDateTime}`;
    }
  };

  return (
    <FormGridContainer>
      <FormGridItem>
        <ReadOnlyField dataTestId="ged-status" label="Envoi de l'avis signé à la GED" value={valueField()} />
      </FormGridItem>
    </FormGridContainer>
  );
};
