import { FieldRenderProps, useField, useForm } from 'react-final-form';
import { useFdjCaseTypeFormValue } from './useFdjCaseTypeFormValue';
import { useState } from 'react';
import FdjDemandFormApiUtils from '../fdjDemandFormApiUtils';
import { FdjDemand } from '../fdjDemandTypes';
import { useFdjAuthenticatedCurrentUser } from '../../security/hooks';
import ApiHelper from '@eogile/agrements-common/src/api/apiHelper';
import { RdiErrorStatus, RdiRequest, RdiResponse } from '../rdiTypes';
import { useIsIntegratedDemandFormValue } from './useIsIntegratedDemandFormValue';
import ApiError from '@eogile/agrements-common/src/api/ApiError';
import { captureException } from '@sentry/react';
import { lightDemandFormField } from '@eogile/agrements-common/src/demand/hooks/useIsLightDemandFormHelper';
import dayjs from 'dayjs';
import { Severity } from '@eogile/agrements-common/src/utils/severityUtils';
import useSnackbar from '@eogile/agrements-common/src/snackbar/useSnackbar';
import { rdiErrorLabels } from '../rdiUtils';
import { FormMode, useFormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import { isFdjUser } from '../../user/fdjUserUtils';

export const useFdjOutletCodeFormHelper = () => {
  const mode = useFormMode();
  const { userDetails } = useFdjAuthenticatedCurrentUser();
  const { value: caseTypeValue } = useFdjCaseTypeFormValue();
  const { input, meta }: FieldRenderProps<string | undefined> = useField('outlet.code');
  const canRequestRdi = mode !== FormMode.READ && isFdjUser(userDetails) && !meta.error && Boolean(caseTypeValue);
  const [existingOngoingDemand, setExistingOngoingDemand] = useState<FdjDemand | null>(null);
  const [pendingRdiRequest, setPendingRdiRequest] = useState(false);
  const { value: isIntegratedDemand } = useIsIntegratedDemandFormValue();
  const { getFieldState, change } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const checkOngoingDemandWithSameOutletCodeExists = async () => {
    if (meta.error || !input.value) {
      return;
    }
    setExistingOngoingDemand(await FdjDemandFormApiUtils.findOngoingDemandByOutletCode(input.value, userDetails));
  };

  const closeOngoingDemandExistsDialog = () => setExistingOngoingDemand(null);

  const requestRdi = async () => {
    try {
      setPendingRdiRequest(true);
      const rdiResponse: RdiResponse = await ApiHelper.get('/api/demands/request-rdi', {
        caseType: caseTypeValue!,
        outletCode: input.value,
        forIntegratedDemand: isIntegratedDemand,
      } as RdiRequest);
      prefillWith(rdiResponse);
    } catch (e) {
      if (e instanceof ApiError) {
        handleRdiError(e);
      }
      captureException(e);
    } finally {
      setPendingRdiRequest(false);
    }
  };

  const prefillWith = (rdiResponse: RdiResponse) => {
    const isLightDemand = Boolean(getFieldState(lightDemandFormField)?.value);
    const rdiOutlet = rdiResponse.outlet;
    change('outlet.name', rdiOutlet.name);
    change('outlet.address.address1', rdiOutlet.address.address);
    change('outlet.address.zipCode', rdiOutlet.address.zipCode);
    change('outlet.address.city', rdiOutlet.address.city);
    if (!isLightDemand && rdiOutlet.phone) {
      change('outlet.phoneNumbers.phone1', rdiOutlet.phone);
    }

    // candidate data can be an empty object in some cases, so we only replace the values
    // when a mandatory field has a value.
    if (Boolean(rdiResponse.candidate?.lastName)) {
      const rdiCandidate = rdiResponse.candidate!;
      const candidatePrefix = `candidates[0]`;
      change(`${candidatePrefix}.civility`, rdiCandidate.civility);
      change(`${candidatePrefix}.lastName`, rdiCandidate.lastName);
      change(`${candidatePrefix}.firstNames`, rdiCandidate.firstName);
      change(`${candidatePrefix}.birthDate`, rdiCandidate.birthDate ? dayjs(rdiCandidate.birthDate) : undefined);
      change(`${candidatePrefix}.birthCity`, rdiCandidate.birthCity);
      if (!isLightDemand) {
        change(`${candidatePrefix}.address.address1`, rdiCandidate.address?.address);
        change(`${candidatePrefix}.address.city`, rdiCandidate.address?.city);
        change(`${candidatePrefix}.address.zipCode`, rdiCandidate.address?.zipCode);
        change(`${candidatePrefix}.phoneNumbers.phone1`, rdiCandidate.phone);
        change(`${candidatePrefix}.email`, rdiCandidate.email);
      }
    }
  };

  const handleRdiError = (error: ApiError) => {
    enqueueSnackbar({
      content: rdiErrorLabels[error.message as RdiErrorStatus],
      severity: Severity.ERROR,
    });
  };

  return {
    canRequestRdi,
    requestRdi,
    existingOngoingDemand,
    checkOngoingDemandWithSameOutletCodeExists,
    closeOngoingDemandExistsDialog,
    pendingRdiRequest,
  };
};
