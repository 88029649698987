import React from 'react';
import { SectionCard } from '@eogile/agrements-common/src/components/cards/SectionCard';
import { FdjDemand } from '../../../fdjDemandTypes';
import FdjComment from './internal/FdjComment';
import { Box } from '@mui/material';
import useComments from '../hooks/useComments';
import { CurrentFdjApplicationUserDetails } from '../../../../security/types';

type FdjDemandCommentsSectionProps = {
  demand: FdjDemand;
  currentUserDetails: CurrentFdjApplicationUserDetails;
  onDemandRefresh: (demand: FdjDemand) => void;
};

function FdjDemandCommentsSection({ demand, currentUserDetails, onDemandRefresh }: FdjDemandCommentsSectionProps) {
  const { comments, ref } = useComments(demand, currentUserDetails, onDemandRefresh);

  return (
    <SectionCard title="Commentaires">
      <Box ref={ref} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {comments.map((comment, i) => (
          <FdjComment key={i} comment={comment} currentUserDetails={currentUserDetails} />
        ))}
      </Box>
    </SectionCard>
  );
}

export default React.memo(FdjDemandCommentsSection);
