import { GedSupervisionDemandsFilters } from './GedSupervisionDemandsFilters';
import React from 'react';
import { useFdjDemandsListLoader } from '../demand/useFdjDemandsListLoader';
import {
  extractPaginationAndSortCommand,
  formValuesToQueryString,
} from '@eogile/agrements-common/src/utils/tableUtils';
import { GedSupervisionDemandsTableCard } from './GedSupervisionDemandsTableCard';

export const GedSupervisionDemandsList = () => {
  const { filterValues, data, requestParams, onPaginationOrSortChange } = useFdjDemandsListLoader('GED_SUPERVISION');

  return (
    <>
      <GedSupervisionDemandsFilters
        initialValues={filterValues}
        formValuesToQueryString={formValuesToQueryString(requestParams)}
      />
      {data && (
        <GedSupervisionDemandsTableCard
          data={data}
          command={extractPaginationAndSortCommand(requestParams)}
          onCommandChange={onPaginationOrSortChange}
        />
      )}
    </>
  );
};
