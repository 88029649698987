import { FdjDemandsSortableFields, FdjSimpleDemandView } from '../demand/fdjDemandListTypes';
import { PaginatedListResponse } from '@eogile/agrements-common/src/api/apiTypes';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { Card, CardContent, CardHeader, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { noResultsTitle, numberOfDemandResults } from '@eogile/agrements-common/src/demand/demandListUtils';
import { GedSupervisionDemandsTableHeader } from './GedSupervisionDemandsTableHeader';
import TableCell from '@eogile/agrements-common/src/components/table/TableCell';
import { formatLocalDateForDisplay, formatZonedDateTimeForDisplay } from '@eogile/agrements-common/src/utils/dateUtils';
import dayjs from 'dayjs';
import { displayAgencyName } from '../demand/fdjDemandUtils';
import { gedRequestStatusLabels } from './gedUtils';
import TablePagination from '@eogile/agrements-common/src/components/table/TablePagination';
import { GedRequestTraceDetailsDialog } from './GedRequestTraceDetailsDialog';

type GedSupervisionDemandsTableCardProps = {
  data: PaginatedListResponse<FdjSimpleDemandView>;
  onCommandChange: (newCommand: PaginationAndSortCommand<FdjDemandsSortableFields>) => void;
  command: PaginationAndSortCommand<FdjDemandsSortableFields>;
};

export const GedSupervisionDemandsTableCard = ({
  data,
  onCommandChange,
  command,
}: GedSupervisionDemandsTableCardProps) => {
  const { numberOfResults, results } = data;
  const [detailDemand, setDetailDemand] = useState<FdjSimpleDemandView | null>(null);

  return (
    <Card>
      <CardHeader
        title={numberOfResults > 0 ? numberOfDemandResults(numberOfResults) : undefined}
        subheader={numberOfResults === 0 ? noResultsTitle : undefined}
      />
      {numberOfResults > 0 && (
        <CardContent>
          <TableContainer>
            <Table size="medium">
              <GedSupervisionDemandsTableHeader onCommandChange={onCommandChange} command={command} />
              <TableBody>
                {results &&
                  results.map((demandDetailsView) => (
                    <TableRow
                      key={demandDetailsView.id}
                      onClick={() => setDetailDemand(demandDetailsView)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>{demandDetailsView.chronoNumber}</TableCell>
                      <TableCell>{demandDetailsView.outletCode}</TableCell>
                      <TableCell>
                        {demandDetailsView.verdictValidationDateTime
                          ? formatLocalDateForDisplay(dayjs(demandDetailsView.verdictValidationDateTime))
                          : ''}
                      </TableCell>
                      <TableCell>
                        {displayAgencyName(demandDetailsView.agencyCode, demandDetailsView.agencyName)}
                      </TableCell>
                      <TableCell>{demandDetailsView.hasSignedVerdictFile ? 'Oui' : 'Non'}</TableCell>
                      <TableCell>
                        {demandDetailsView.gedRequestStatus
                          ? gedRequestStatusLabels[demandDetailsView.gedRequestStatus]
                          : "Pas d'envoi effectué"}
                      </TableCell>
                      <TableCell>
                        {demandDetailsView.gedRequestSentDateTime
                          ? formatZonedDateTimeForDisplay(dayjs(demandDetailsView.gedRequestSentDateTime))
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination<FdjDemandsSortableFields>
            numberOfResults={numberOfResults}
            onCommandChange={onCommandChange}
            command={command}
          />
        </CardContent>
      )}
      <GedRequestTraceDetailsDialog demand={detailDemand} onClose={() => setDetailDemand(null)} />
    </Card>
  );
};
