import {
  FdjAgreementType,
  FdjCandidateType,
  FdjCaseType,
  FdjDemand,
  FdjFullDemand,
  FdjLightDemand,
} from './fdjDemandTypes';
import { DemandStatus } from '@eogile/agrements-common/src/demand/demandTypes';
import { FdjSimpleDemandView } from './fdjDemandListTypes';
import { Agency } from '@eogile/agrements-common/src/common-types/directionTypes';

export const fdjCaseTypeLabels: Record<FdjCaseType, string> = {
  [FdjCaseType.CREATION]: 'Création',
  [FdjCaseType.TRANSFER]: 'Cession',
  [FdjCaseType.AGREEMENT_ADDITION]: "Ajout d'agrément",
  [FdjCaseType.CAPITAL_MODIFICATION]: 'Modification de capital',
  [FdjCaseType.GOVERNING_BODY_MODIFICATION]: 'Changement des organes de direction',
  [FdjCaseType.CAPITAL_AND_GOVERNING_BODY_MODIFICATION]: 'Modification de capital et des organes de direction',
  [FdjCaseType.MOVE]: 'Déménagement',
};

export const fdjAgreementTypeLabels: Record<FdjAgreementType, string> = {
  [FdjAgreementType.LOTTERY]: 'Loterie',
  [FdjAgreementType.SPORTS_BETTING]: 'Paris Sportifs',
  [FdjAgreementType.LOTTERY_AND_SPORTS_BETTING]: 'Loterie et Paris Sportifs',
};

export const fdjCandidateTypeLabels: Record<FdjCandidateType, string> = {
  [FdjCandidateType.LEGAL_REPRESENTATIVE]: 'Représentant légal',
  [FdjCandidateType.PRESIDENT]: 'Président',
  [FdjCandidateType.MANAGER]: 'Gérant',
  [FdjCandidateType.CO_MANAGER]: 'Co-gérant',
  [FdjCandidateType.ASSOCIATE]: 'Associé',
};

export const isFdjLightDemand = (demand: FdjDemand): demand is FdjLightDemand => {
  return demand.receivedGrantedVerdictInPast12Months;
};

export const isFdjFullDemand = (demand: FdjDemand): demand is FdjFullDemand => {
  return !demand.receivedGrantedVerdictInPast12Months;
};

export const compatibleAgreementTypesForCaseType = (caseType: FdjCaseType | undefined): FdjAgreementType[] => {
  if (!caseType || ![FdjCaseType.CREATION, FdjCaseType.TRANSFER, FdjCaseType.AGREEMENT_ADDITION].includes(caseType)) {
    return [];
  }
  if (caseType === FdjCaseType.AGREEMENT_ADDITION) {
    return Object.values(FdjAgreementType).filter((type) => type !== FdjAgreementType.LOTTERY_AND_SPORTS_BETTING);
  }
  return Object.values(FdjAgreementType);
};

export const allFdjDemandStatuses = Object.values(DemandStatus);

export const displayLegalRepresentativeName = (demandDetailsView: FdjSimpleDemandView) => {
  return [demandDetailsView.legalRepresentativeLastName, demandDetailsView.legalRepresentativeFirstNames]
    .filter((chain) => chain)
    .join(' ');
};

export const displayAgencyName = (code: string, name: string) => {
  return code + ' - ' + name;
};

export const computeAgencyName = (agency: Agency) => displayAgencyName(agency.code, agency.name);
