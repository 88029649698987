import {
  AttachmentHelper,
  AttachmentPresence,
  AttachmentPresenceRule,
  AttachmentPresenceRules,
} from '@eogile/agrements-common/src/demand/attachmentTypes';
import { FdjAttachmentType, FdjCaseType, FdjDemand } from './fdjDemandTypes';
import { AcquirerType, OperatingMode } from '@eogile/agrements-common/src/demand/demandTypes';
import { getDiffInYearsRelativeToNow } from '@eogile/agrements-common/src/utils/dateUtils';
import dayjs from 'dayjs';
import { isFdjFullDemand, isFdjLightDemand } from './fdjDemandUtils';
import { fdjAttachmentTooltipContents } from './components/fdjAttachmentTooltipContents';
import { attachmentTypeDisplayOrderWeightsFromOrderedList } from '@eogile/agrements-common/src/demand/attachmentUtils';

const conditionsUtil = (demand: FdjDemand) => ({
  isIntegratedDemand: () => demand.integratedDemand.integratedDemand,
  acquirer: (wanted: AcquirerType) => isFdjFullDemand(demand) && demand.acquirer.type === wanted,
  caseType: (wanted: FdjCaseType) => demand.caseType === wanted,
  caseTypeIn: (wanted: FdjCaseType[]) => wanted.includes(demand.caseType),
  operatingMode: (wanted: OperatingMode) => isFdjFullDemand(demand) && demand.outlet.operatingMode === wanted,
  acquisitionDate: (wanted: '-3years' | '+3years') => {
    if (isFdjLightDemand(demand)) {
      return false;
    }
    const diff = getDiffInYearsRelativeToNow(dayjs(demand.outlet.acquisitionDate));
    return wanted === '+3years' ? diff >= 3 : diff < 3;
  },
});

const avisImpositionOrLivretFamilleCondition: (
  type: FdjAttachmentType.LivretFamille | FdjAttachmentType.AvisImposition,
) => AttachmentPresenceRule<FdjDemand> = (type) => (demand: FdjDemand) => {
  const { isIntegratedDemand } = conditionsUtil(demand);
  if (isIntegratedDemand() || isFdjLightDemand(demand)) {
    return 'INCOMPATIBLE';
  }
  return 'MANDATORY';
};

const acteAchatOrAdjudicationCondition: (
  type: FdjAttachmentType.ActeAchat | FdjAttachmentType.Adjudication,
) => AttachmentPresenceRule<FdjDemand> = (type) => (demand: FdjDemand) => {
  const { isIntegratedDemand, operatingMode, acquisitionDate } = conditionsUtil(demand);
  if (isIntegratedDemand() || isFdjLightDemand(demand)) {
    return 'INCOMPATIBLE';
  }
  if (operatingMode(OperatingMode.DIRECT_EXPLOITATION) && acquisitionDate('-3years')) {
    return type === FdjAttachmentType.ActeAchat ? 'MANDATORY' : 'FACULTATIVE';
  }
  return 'INCOMPATIBLE';
};

const pretOrJustificationApportOrPlanFinancementCondition: (
  type: FdjAttachmentType.AccordPret | FdjAttachmentType.JustificationApports | FdjAttachmentType.PlanFinancement,
) => AttachmentPresenceRule<FdjDemand> = (type) => (demand: FdjDemand) => {
  const { isIntegratedDemand, caseTypeIn, operatingMode, acquisitionDate } = conditionsUtil(demand);
  if (isIntegratedDemand()) {
    return 'INCOMPATIBLE';
  }
  if (
    isFdjLightDemand(demand) &&
    caseTypeIn([
      FdjCaseType.CAPITAL_MODIFICATION,
      FdjCaseType.CAPITAL_AND_GOVERNING_BODY_MODIFICATION,
      FdjCaseType.MOVE,
    ])
  ) {
    return 'FACULTATIVE';
  } else if (
    isFdjFullDemand(demand) &&
    acquisitionDate('-3years') &&
    operatingMode(OperatingMode.DIRECT_EXPLOITATION)
  ) {
    return 'MANDATORY';
  }
  return 'INCOMPATIBLE';
};

const attestationSurHonneurOrPlanCadastralCondition: (
  type: FdjAttachmentType.AttestationSurHonneur | FdjAttachmentType.PlanCadastral,
) => AttachmentPresenceRule<FdjDemand> = (type) => (demand: FdjDemand) => {
  const { isIntegratedDemand, caseType } = conditionsUtil(demand);
  if (isIntegratedDemand() || (isFdjFullDemand(demand) && caseType(FdjCaseType.CREATION))) {
    return 'MANDATORY';
  }
  return 'INCOMPATIBLE';
};

const attestationEnqueteSccjCondition = (demand: FdjDemand): AttachmentPresence => {
  if (demand.integratedDemand.integratedDemand) {
    return 'INCOMPATIBLE';
  }
  return 'MANDATORY';
};

const fdjAttachmentPresenceRules: AttachmentPresenceRules<FdjDemand, FdjAttachmentType> = {
  [FdjAttachmentType.PieceIdentite]: (demand) => {
    const { isIntegratedDemand } = conditionsUtil(demand);
    if (isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    return 'MANDATORY';
  },
  [FdjAttachmentType.AvisImposition]: avisImpositionOrLivretFamilleCondition(FdjAttachmentType.AvisImposition),
  [FdjAttachmentType.LivretFamille]: avisImpositionOrLivretFamilleCondition(FdjAttachmentType.LivretFamille),
  [FdjAttachmentType.ActeAchat]: acteAchatOrAdjudicationCondition(FdjAttachmentType.ActeAchat),
  [FdjAttachmentType.Adjudication]: acteAchatOrAdjudicationCondition(FdjAttachmentType.Adjudication),
  [FdjAttachmentType.ContratLocationGerance]: (demand) => {
    const { isIntegratedDemand, operatingMode, acquisitionDate } = conditionsUtil(demand);
    if (isIntegratedDemand() || isFdjLightDemand(demand)) {
      return 'INCOMPATIBLE';
    }
    if (operatingMode(OperatingMode.LEASE_MANAGEMENT) && acquisitionDate('-3years')) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [FdjAttachmentType.ConventionOccupation]: (demand) => {
    const { isIntegratedDemand, operatingMode } = conditionsUtil(demand);
    if (isIntegratedDemand() || isFdjLightDemand(demand)) {
      return 'INCOMPATIBLE';
    }
    if (operatingMode(OperatingMode.OCCUPATION)) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [FdjAttachmentType.AccordPret]: pretOrJustificationApportOrPlanFinancementCondition(FdjAttachmentType.AccordPret),
  [FdjAttachmentType.JustificationApports]: pretOrJustificationApportOrPlanFinancementCondition(
    FdjAttachmentType.JustificationApports,
  ),
  [FdjAttachmentType.PlanFinancement]: pretOrJustificationApportOrPlanFinancementCondition(
    FdjAttachmentType.PlanFinancement,
  ),
  [FdjAttachmentType.ExtraitK]: () => 'INCOMPATIBLE',
  [FdjAttachmentType.ExtraitKbis]: (demand) => {
    const { isIntegratedDemand, caseTypeIn, operatingMode } = conditionsUtil(demand);
    if (isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      isFdjLightDemand(demand) &&
      caseTypeIn([
        FdjCaseType.GOVERNING_BODY_MODIFICATION,
        FdjCaseType.CAPITAL_AND_GOVERNING_BODY_MODIFICATION,
        FdjCaseType.MOVE,
      ])
    ) {
      return 'MANDATORY';
    } else if (isFdjFullDemand(demand) && operatingMode(OperatingMode.DIRECT_EXPLOITATION)) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [FdjAttachmentType.StatutsSociete]: (demand) => {
    const { isIntegratedDemand, caseTypeIn, acquirer, operatingMode } = conditionsUtil(demand);
    if (isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      isFdjLightDemand(demand) &&
      caseTypeIn([
        FdjCaseType.GOVERNING_BODY_MODIFICATION,
        FdjCaseType.CAPITAL_AND_GOVERNING_BODY_MODIFICATION,
        FdjCaseType.MOVE,
      ])
    ) {
      return 'MANDATORY';
    } else if (
      isFdjFullDemand(demand) &&
      operatingMode(OperatingMode.DIRECT_EXPLOITATION) &&
      acquirer(AcquirerType.COMPANY)
    ) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [FdjAttachmentType.LiasseFiscale]: (demand) => {
    const { isIntegratedDemand, acquirer, operatingMode } = conditionsUtil(demand);
    if (isIntegratedDemand() || isFdjLightDemand(demand)) {
      return 'INCOMPATIBLE';
    }
    if (operatingMode(OperatingMode.DIRECT_EXPLOITATION) && acquirer(AcquirerType.COMPANY)) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [FdjAttachmentType.CessionParts]: (demand) => {
    const { isIntegratedDemand, caseTypeIn } = conditionsUtil(demand);
    if (isIntegratedDemand() || isFdjFullDemand(demand)) {
      return 'INCOMPATIBLE';
    }
    if (
      caseTypeIn([
        FdjCaseType.CAPITAL_MODIFICATION,
        FdjCaseType.CAPITAL_AND_GOVERNING_BODY_MODIFICATION,
        FdjCaseType.MOVE,
      ])
    ) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [FdjAttachmentType.JustificationFondsFinancementLocationGerance]: (demand) => {
    const { isIntegratedDemand, acquisitionDate, operatingMode } = conditionsUtil(demand);
    if (isIntegratedDemand() || isFdjLightDemand(demand)) {
      return 'INCOMPATIBLE';
    }
    if (operatingMode(OperatingMode.LEASE_MANAGEMENT) && acquisitionDate('-3years')) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [FdjAttachmentType.ContratAchatExclusifBoissons]: (demand) => {
    const { isIntegratedDemand, acquisitionDate, operatingMode } = conditionsUtil(demand);
    if (isIntegratedDemand() || isFdjLightDemand(demand)) {
      return 'INCOMPATIBLE';
    }
    if (operatingMode(OperatingMode.DIRECT_EXPLOITATION) && acquisitionDate('-3years')) {
      return 'FACULTATIVE';
    }
    return 'INCOMPATIBLE';
  },
  [FdjAttachmentType.AttestationNonChangementOrganesDirection]: () => 'INCOMPATIBLE', // not used anymore
  [FdjAttachmentType.NonChangementOrganesDirection]: (demand) => {
    const { isIntegratedDemand, caseTypeIn } = conditionsUtil(demand);
    if (isIntegratedDemand() || isFdjFullDemand(demand)) {
      return 'INCOMPATIBLE';
    }
    if (caseTypeIn([FdjCaseType.AGREEMENT_ADDITION, FdjCaseType.CREATION, FdjCaseType.CAPITAL_MODIFICATION])) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [FdjAttachmentType.AttestationSurHonneur]: attestationSurHonneurOrPlanCadastralCondition(
    FdjAttachmentType.AttestationSurHonneur,
  ),
  [FdjAttachmentType.AttestationEnqueteSccj]: attestationEnqueteSccjCondition,
  [FdjAttachmentType.PlanCadastral]: attestationSurHonneurOrPlanCadastralCondition(FdjAttachmentType.PlanCadastral),
  [FdjAttachmentType.AutresPieces]: () => {
    return 'FACULTATIVE';
  },
};

const fdjAttachmentTypeLabels: Record<FdjAttachmentType, string> = {
  [FdjAttachmentType.PieceIdentite]: "Pièce d'identité",
  [FdjAttachmentType.AvisImposition]: "Avis d'imposition",
  [FdjAttachmentType.LivretFamille]: 'Livret de famille',
  [FdjAttachmentType.AttestationEnqueteSccj]: 'Attestation enquête SCCJ',
  [FdjAttachmentType.ActeAchat]: "Acte d'achat enregistré",
  [FdjAttachmentType.Adjudication]: 'Jugement du tribunal suite à adjudication',
  [FdjAttachmentType.ContratLocationGerance]: 'Contrat de location-gérance',
  [FdjAttachmentType.ConventionOccupation]: "Convention d'occupation",
  [FdjAttachmentType.AccordPret]: 'Accord de prêt',
  [FdjAttachmentType.JustificationApports]: 'Justification des apports',
  [FdjAttachmentType.PlanFinancement]: 'Plan de financement',
  [FdjAttachmentType.ExtraitK]: 'Extrait K',
  [FdjAttachmentType.ExtraitKbis]: 'Extrait Kbis',
  [FdjAttachmentType.StatutsSociete]: 'Statuts de la société ou projet',
  [FdjAttachmentType.LiasseFiscale]: 'Liasse fiscale',
  [FdjAttachmentType.CessionParts]: 'Contrat ou projet de cession de parts',
  [FdjAttachmentType.JustificationFondsFinancementLocationGerance]:
    'Justification des fonds de financement en location gérance',
  [FdjAttachmentType.ContratAchatExclusifBoissons]: "Contrat d'achat exclusif de boissons",
  [FdjAttachmentType.AttestationNonChangementOrganesDirection]:
    'Attestation de non changement des organes de direction',
  [FdjAttachmentType.NonChangementOrganesDirection]: 'Attestation de non changement des organes de direction',
  [FdjAttachmentType.AttestationSurHonneur]: "Attestation sur l'honneur",
  [FdjAttachmentType.PlanCadastral]: 'Plan cadastral',
  [FdjAttachmentType.AutresPieces]: 'Autre pièce',
};

export const fdjAttachmentHelper: AttachmentHelper<FdjDemand, FdjAttachmentType> = {
  attachmentTypeDisplayOrderWeights: attachmentTypeDisplayOrderWeightsFromOrderedList([
    FdjAttachmentType.PieceIdentite,
    FdjAttachmentType.AvisImposition,
    FdjAttachmentType.LivretFamille,
    FdjAttachmentType.AttestationEnqueteSccj,
    FdjAttachmentType.ActeAchat,
    FdjAttachmentType.Adjudication,
    FdjAttachmentType.ContratLocationGerance,
    FdjAttachmentType.ConventionOccupation,
    FdjAttachmentType.AccordPret,
    FdjAttachmentType.JustificationApports,
    FdjAttachmentType.PlanFinancement,
    FdjAttachmentType.ExtraitK,
    FdjAttachmentType.ExtraitKbis,
    FdjAttachmentType.StatutsSociete,
    FdjAttachmentType.LiasseFiscale,
    FdjAttachmentType.CessionParts,
    FdjAttachmentType.JustificationFondsFinancementLocationGerance,
    FdjAttachmentType.ContratAchatExclusifBoissons,
    FdjAttachmentType.AttestationNonChangementOrganesDirection,
    FdjAttachmentType.NonChangementOrganesDirection,
    FdjAttachmentType.AttestationSurHonneur,
    FdjAttachmentType.PlanCadastral,
    FdjAttachmentType.AutresPieces,
  ]),
  attachmentPresenceRules: fdjAttachmentPresenceRules,
  attachmentTypeInfoTooltips: fdjAttachmentTooltipContents,
  attachmentTypeLabels: fdjAttachmentTypeLabels,
  attachmentTypesAllowMultiple: [FdjAttachmentType.AutresPieces],
  attachmentTypesForCandidate: [
    FdjAttachmentType.PieceIdentite,
    FdjAttachmentType.AvisImposition,
    FdjAttachmentType.LivretFamille,
    FdjAttachmentType.AttestationEnqueteSccj,
  ],
};
