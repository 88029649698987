import { useField } from 'react-final-form';

export const ongoingPmuDemandFormField = 'ongoingPmuDemand';

export const useIsOngoingPmuDemandFormValue = () => {
  const isOngoingPmuDemand = useField(ongoingPmuDemandFormField, {
    subscription: { value: true, dirty: true },
  });
  return {
    dirty: isOngoingPmuDemand.meta.dirty,
    value: (isOngoingPmuDemand.input.value ?? false) as boolean,
  };
};
