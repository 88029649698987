import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { FormMode, useFormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import MainActionButton from '@eogile/agrements-common/src/components/buttons/MainActionButton';
import { FdjDemandCandidateSection } from './FdjDemandCandidateSection';
import { useAcquirerTypeFormValue } from '@eogile/agrements-common/src/demand/hooks/useAcquirerTypeFormValue';
import { AcquirerType } from '@eogile/agrements-common/src/demand/demandTypes';
import { CandidateFormValues } from '@eogile/agrements-common/src/demand/demandFormTypes';
import { FdjCandidateType, FdjDemand } from '../fdjDemandTypes';

type FdjDemandCandidatesArrayProps = {
  demand: FdjDemand | undefined;
};
export const FdjDemandCandidatesArray = ({ demand }: FdjDemandCandidatesArrayProps) => {
  const mode = useFormMode();
  const acquirerType = useAcquirerTypeFormValue();

  const addCandidate = (
    fields: FieldArrayRenderProps<Partial<CandidateFormValues<FdjCandidateType>>, any>['fields'],
  ) => {
    if (acquirerType === AcquirerType.IN_OWN_NAME) {
      fields.push({ type: FdjCandidateType.ASSOCIATE });
    } else {
      fields.push(undefined as any);
    }
  };

  return (
    <FieldArray name="candidates">
      {({ fields }) => (
        <>
          {fields.map((name, index) => (
            <FdjDemandCandidateSection key={name} name={name} index={index} demand={demand} />
          ))}
          {mode !== FormMode.READ && (
            <MainActionButton
              sx={{ mt: '20px' }}
              action={() => addCandidate(fields)}
              label="Ajouter un associé"
              dataTestId="add-candidate-btn"
            />
          )}
        </>
      )}
    </FieldArray>
  );
};
