import React from 'react';
import { Card, CardContent, Table, TableBody, TableContainer } from '@mui/material';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { FdjUsersSortableFields } from '../fdjUsersTypes';
import FdjUsersTableHeader from './FdjUsersTableHeader';
import { PaginatedListResponse } from '@eogile/agrements-common/src/api/apiTypes';
import { FdjUser } from '../../fdjUserTypes';
import UsersTableHeader from '@eogile/agrements-common/src/user/list/components/UsersTableHeader';
import NoUsersFound from '@eogile/agrements-common/src/user/list/components/NoUsersFound';
import FdjUsersTableRow from './FdjUsersTableRow';
import TablePagination from '@eogile/agrements-common/src/components/table/TablePagination';

type Props = {
  onCommandChange: (newCommand: PaginationAndSortCommand<FdjUsersSortableFields>) => void;
  command: PaginationAndSortCommand<FdjUsersSortableFields>;
  paginatedUsers: PaginatedListResponse<FdjUser> | null;
  reloadUsers: () => Promise<void>;
};

const FdjUsersTable = ({ command, onCommandChange, paginatedUsers, reloadUsers }: Props) => {
  if (!paginatedUsers) {
    return null;
  }

  const { numberOfResults, results } = paginatedUsers;
  return (
    <Card>
      <UsersTableHeader numberOfUsers={numberOfResults} />
      <CardContent>
        {numberOfResults === 0 && <NoUsersFound />}
        {numberOfResults > 0 && (
          <>
            <TableContainer>
              <Table>
                <FdjUsersTableHeader onCommandChange={onCommandChange} command={command} />
                <TableBody>
                  {results.map((user) => (
                    <FdjUsersTableRow key={user.id} user={user} reloadUsers={reloadUsers} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination<FdjUsersSortableFields>
              numberOfResults={numberOfResults}
              onCommandChange={onCommandChange}
              command={command}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default FdjUsersTable;
