import { useFdjVerdictTypeFormHelper } from './useFdjVerdictTypeFormHelper';
import { FdjDemand } from '../../../fdjDemandTypes';
import { VerdictTypeSelectAdapter } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/VerdictTypeSelectAdapter';
import { useStandardRejectedVerdictFormHelper } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/useStandardRejectedVerdictFormHelper';
import { fdjActiveRejectionReasons } from './fdjVerdictInputFormUtils';
import { VerdictType } from '@eogile/agrements-common/src/demand/demandTypes';
import { RejectionReasonSelectAdapter } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/RejectionReasonSelectAdapter';
import { useFdjCancelledVerdictFormHelper } from './useFdjCancelledVerdictFormHelper';
import { CancellationReasonSelectAdapter } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/CancellationReasonSelectAdapter';
import { CancellationCommentTextFieldAdapter } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/CancellationCommentTextFieldAdapter';
import { Grid } from '@mui/material';
import { hasAskedCancellation } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/verdictInputFormUtils';
import { VerdictInputCancellationAskedText } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/VerdictInputCancellationAskedText';

export const FdjVerdictInputFormContent = ({ demand }: { demand: FdjDemand }) => {
  const { currentVerdictType, availableVerdictTypes } = useFdjVerdictTypeFormHelper(demand);
  const { activeRejectionReasons } = useStandardRejectedVerdictFormHelper(fdjActiveRejectionReasons);
  const { activeCancellationReasons, supportsCancellationCommentField, supportsMinistryCancellationFields } =
    useFdjCancelledVerdictFormHelper(demand);

  return (
    <Grid container spacing={2}>
      {hasAskedCancellation(demand) && (
        <Grid item xs={12}>
          <VerdictInputCancellationAskedText />
        </Grid>
      )}
      <Grid item xs={12}>
        <VerdictTypeSelectAdapter availableVerdictTypes={availableVerdictTypes} />
      </Grid>
      {currentVerdictType === VerdictType.REJECTED && (
        <Grid item xs={12}>
          <RejectionReasonSelectAdapter availableRejectionReasons={activeRejectionReasons} />
        </Grid>
      )}
      {currentVerdictType === VerdictType.CANCELLED && supportsMinistryCancellationFields && (
        <>
          <Grid item xs={12}>
            <CancellationReasonSelectAdapter availableCancellationReasons={activeCancellationReasons} />
          </Grid>
          {supportsCancellationCommentField && (
            <Grid item xs={12}>
              <CancellationCommentTextFieldAdapter label="Détails" />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
