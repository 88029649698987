import { VerdictInputDialogProps } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/verdictInputComponentTypes';
import { FdjDemand } from '../../../fdjDemandTypes';
import { useMemo } from 'react';
import { buildFdjVerdictInputCommand, buildInitialFdjVerdictInputFormValues } from './fdjVerdictInputFormUtils';
import { FdjVerdictInputFormContent } from './FdjVerdictInputFormContent';
import { VerdictInputDialogWrapper } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/VerdictInputDialogWrapper';

export const FdjVerdictInputDialog = ({
  demand,
  open,
  onClose,
  onSubmitSuccess,
}: VerdictInputDialogProps<FdjDemand>) => {
  const initialFormValues = useMemo(() => buildInitialFdjVerdictInputFormValues(demand), [demand]);

  return (
    <VerdictInputDialogWrapper
      demand={demand}
      open={open}
      onClose={onClose}
      initialFormValues={initialFormValues}
      formValuesToCommand={buildFdjVerdictInputCommand}
      onSubmitSuccess={onSubmitSuccess}
    >
      <FdjVerdictInputFormContent demand={demand} />
    </VerdictInputDialogWrapper>
  );
};
