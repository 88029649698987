import { FdjUserProfile } from './fdjUserTypes';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import { CurrentFdjApplicationUserDetails, CurrentFdjUserDetails } from '../security/types';
import { FdjAgency } from '../common-types/direction/fdjDirectionTypes';

export const fdjProfileDisplayLabels: Record<FdjUserProfile, string> = {
  [FdjUserProfile.FDJ_ADMIN]: 'Administrateur',
  [FdjUserProfile.FDJ_AGENCY]: 'Secteur',
  [FdjUserProfile.FDJ_DEMAND_READER]: 'FDJ consultation',
  [FdjUserProfile.FDJ_DEMAND_WRITER]: 'FDJ modification',
};

export const isFdjUser = (user: CurrentFdjApplicationUserDetails): user is CurrentFdjUserDetails =>
  user.company === UserCompany.FDJ;

/**
 * When not {@code undefined}, then the user will be considered as an "agency user", which restricts the demands
 * the user is allowed to see.
 */
export const currentUserAgency = (user: CurrentFdjApplicationUserDetails): FdjAgency | undefined => {
  if (isFdjUser(user)) {
    return user.agency ?? undefined;
  }
  return undefined;
};
