import { User } from '@eogile/agrements-common/src/user/usersTypes';

export type FdjUser = User &
  Readonly<{
    profileCodes: readonly FdjUserProfile[];
    agency: AgencySummary;
  }>;

export enum FdjUserProfile {
  FDJ_ADMIN = 'FDJ_ADMIN',
  FDJ_DEMAND_WRITER = 'FDJ_DEMAND_WRITER',
  FDJ_DEMAND_READER = 'FDJ_DEMAND_READER',
  FDJ_AGENCY = 'FDJ_AGENCY',
}

export type AgencySummary = Readonly<{
  code: string;
  name: string;
}>;
