import { useLocation, useNavigate } from 'react-router-dom';
import { useFdjAuthenticatedCurrentUser } from '../security/hooks';
import {
  FdjDemandsLoadRequestContext,
  FdjDemandsLoadRequestParams,
  FdjDemandsSortableFields,
  FdjSimpleDemandView,
} from './fdjDemandListTypes';
import { useMemo } from 'react';
import { parseFdjDemandsListQueryString, toFdjDemandsListApiParams } from './filters/fdjDemandsFiltersUtils';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { toQueryString } from '@eogile/agrements-common/src/utils/queryStringUtils';
import {
  extractFormValuesFromLoadRequestParams,
  mergePaginationAndSortCommandWithParams,
} from '@eogile/agrements-common/src/utils/tableUtils';
import { useDataLoader } from '@eogile/agrements-common/src/hooks/useDataLoader';
import { PaginatedListResponse } from '@eogile/agrements-common/src/api/apiTypes';

export const useFdjDemandsListLoader = (context: FdjDemandsLoadRequestContext) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails } = useFdjAuthenticatedCurrentUser();

  const requestParams: FdjDemandsLoadRequestParams = useMemo(() => {
    return parseFdjDemandsListQueryString(context, location.search, userDetails);
  }, [context, location.search, userDetails]);

  const onPaginationOrSortChange = (newCommand: PaginationAndSortCommand<FdjDemandsSortableFields>) => {
    const newSearch = toQueryString(mergePaginationAndSortCommandWithParams(requestParams, newCommand));
    navigate({ search: newSearch }, { replace: false });
  };

  const apiParams = useMemo(
    () => toFdjDemandsListApiParams(context, requestParams, userDetails),
    [context, requestParams, userDetails],
  );
  const apiPath = context === 'DEMANDS_LIST' ? '/api/demands' : '/api/ged/demands';
  const { data } = useDataLoader<PaginatedListResponse<FdjSimpleDemandView>>(apiPath, apiParams);

  const filterValues = extractFormValuesFromLoadRequestParams(requestParams);

  return {
    filterValues,
    apiParams,
    data,
    requestParams,
    onPaginationOrSortChange,
  };
};
