import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import SortableTableCell from '@eogile/agrements-common/src/components/table/SortableTableCell';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { FdjDemandsSortableFields } from '../fdjDemandListTypes';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import { useFdjAuthenticatedCurrentUser } from '../../security/hooks';
import TableCell from '@eogile/agrements-common/src/components/table/TableCell';

type DemandsTableHeaderProps = {
  onCommandChange: (newCommand: PaginationAndSortCommand<FdjDemandsSortableFields>) => void;
  command: PaginationAndSortCommand<FdjDemandsSortableFields>;
};

const FdjDemandsTableHeader = ({ onCommandChange, command }: DemandsTableHeaderProps) => {
  const { userDetails } = useFdjAuthenticatedCurrentUser();

  return (
    <TableHead>
      <TableRow>
        <SortableTableCell<FdjDemandsSortableFields>
          label="No dossier"
          sortField="CHRONO_NUMBER"
          onChange={onCommandChange}
          command={command}
        />

        {userDetails.company === UserCompany.FDJ && (
          <SortableTableCell<FdjDemandsSortableFields>
            label="Représentant légal"
            sortField="LEGAL_REPRESENTATIVE"
            onChange={onCommandChange}
            command={command}
          />
        )}
        {userDetails.company === UserCompany.MINISTRY && (
          <SortableTableCell<FdjDemandsSortableFields>
            label="Nom du PDV"
            sortField="OUTLET_NAME"
            onChange={onCommandChange}
            command={command}
          />
        )}
        <SortableTableCell<FdjDemandsSortableFields>
          label="Ville du PDV"
          sortField="OUTLET_CITY"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<FdjDemandsSortableFields>
          label="Direction régionale"
          sortField="REGIONAL_DIRECTION_NAME"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<FdjDemandsSortableFields>
          label="Secteur"
          sortField="AGENCY"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<FdjDemandsSortableFields>
          label="Date d'envoi"
          sortField="VALIDATION_DATE"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<FdjDemandsSortableFields>
          label="Date de réception"
          sortField="RECEPTION_DATE"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<FdjDemandsSortableFields>
          label="Statut"
          sortField="STATUS"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<FdjDemandsSortableFields>
          label="Date d'avis"
          sortField="AVIS_DATE"
          onChange={onCommandChange}
          command={command}
        />
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default FdjDemandsTableHeader;
