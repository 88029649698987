import { FdjDemand, FdjRootDemandView } from './fdjDemandTypes';
import ApiHelper from '@eogile/agrements-common/src/api/apiHelper';
import { APPLICATION_JSON_DETAILED } from '@eogile/agrements-common/src/utils/mediaTypeUtils';
import { CurrentFdjApplicationUserDetails } from '../security/types';
import { currentUserAgency } from '../user/fdjUserUtils';
import { allNonClosedStatuses } from '@eogile/agrements-common/src/demand/demandUtils';
import { FdjDemandSearchCommand } from './fdjDemandListTypes';

const findDemandWithGrantedVerdictByChronoNumber = async (chronoNumber: string): Promise<FdjRootDemandView> => {
  try {
    return await ApiHelper.get(`/api/demands/root`, { chronoNumber });
  } catch (e) {
    throw new Error('Could not find existing demand for this chrono number');
  }
};

const findOngoingDemandByOutletCode = async (
  outletCode: string,
  userDetails: CurrentFdjApplicationUserDetails,
): Promise<FdjDemand | null> => {
  const command: FdjDemandSearchCommand = {
    page: 0,
    pageSize: 1,
    exactOutletCode: outletCode,
    agencyCode: currentUserAgency(userDetails)?.code ?? null,
    statuses: allNonClosedStatuses,
    commentsStatus: null,
  };
  const ongoingDemands: FdjDemand[] = await ApiHelper.get('/api/demands', command, {
    'Content-Type': APPLICATION_JSON_DETAILED,
  });
  return ongoingDemands.length > 0 ? ongoingDemands[0] : null;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  findDemandWithGrantedVerdictByChronoNumber,
  findOngoingDemandByOutletCode,
};
