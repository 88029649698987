import { FdjCaseType } from './fdjDemandTypes';
import { Civility } from '@eogile/agrements-common/src/common-types/civilityTypes';
import { LocalDate } from '@eogile/agrements-common/src/common-types/dateTypes';

export type RdiRequest = Readonly<{
  caseType: FdjCaseType;
  outletCode: string;
  forIntegratedDemand: boolean;
}>;

export type RdiResponse = Readonly<{
  status: 'SUCCESS';
  outlet: RdiOutlet;
  candidate?: RdiCandidate;
}>;

export type RdiOutlet = Readonly<{
  name: string;
  phone?: string;
  address: RdiAddress;
}>;

export type RdiAddress = Readonly<{
  address?: string;
  zipCode?: string;
  city?: string;
}>;

export type RdiCandidate = Readonly<{
  firstName?: string;
  lastName?: string;
  civility?: Civility;
  birthCity?: string;
  birthDate?: LocalDate;
  address?: RdiAddress;
  phone?: string;
  email?: string;
}>;

export enum RdiErrorStatus {
  MISSING_OUTLET = 'MISSING_OUTLET',
  MISSING_CANDIDATE = 'MISSING_CANDIDATE',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
}
