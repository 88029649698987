import React, { useEffect } from 'react';
import AgencySelect from '@eogile/agrements-common/src/components/agencies/AgencySelect';
import { computeAgencyName } from '../../../demand/fdjDemandUtils';
import { useField } from 'react-final-form';
import { FdjUserProfile } from '../../fdjUserTypes';

/**
 * Field representing the agency of a FDJ user.
 *
 * The field is only displayed if "FDJ Agency" profile is selected.
 */
const FdjUserAgencyField = () => {
  const {
    input: { value: profileCodes = [] },
  } = useField<FdjUserProfile[]>('profiles');
  const { input } = useField('agencyCode', { subscription: { value: true } });
  const shouldDisplayField = profileCodes.includes(FdjUserProfile.FDJ_AGENCY);

  useEffect(() => {
    if (!shouldDisplayField) {
      input.onChange(null);
    }
  }, [shouldDisplayField, input]);

  if (shouldDisplayField) {
    return <AgencySelect agencyName={computeAgencyName} disabled={false} fieldLabel="Secteur" required />;
  }
  return null;
};

export default FdjUserAgencyField;
