import React from 'react';
import FdjUsersFilters from './FdjUsersFilters';
import useUsersListHelper from '../hooks/useUsersListHelper';
import FdjUsersTable from './FdjUsersTable';

/**
 * Component in charge of displaying the list of FDJ users with
 * its filters.
 */
const FdjUsersList = () => {
  const { filterValues, onPaginationOrSortChange, paginatedUsers, reloadUsers } = useUsersListHelper();
  return (
    <>
      <FdjUsersFilters filterValues={filterValues} />
      <FdjUsersTable
        command={filterValues}
        onCommandChange={onPaginationOrSortChange}
        paginatedUsers={paginatedUsers}
        reloadUsers={reloadUsers}
      />
    </>
  );
};

export default FdjUsersList;
