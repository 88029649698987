import { Dialog, DialogActions, DialogContent } from '@mui/material';
import MainActionButton from '@eogile/agrements-common/src/components/buttons/MainActionButton';
import SecondaryActionButton from '@eogile/agrements-common/src/components/buttons/SecondaryActionButton';
import { useNavigationHelper } from '@eogile/agrements-common/src/navigation/useNavigationHelper';
import { getDemandsList } from '@eogile/agrements-common/src/navigation/navigationUrlService';
import { FdjDemand } from '../fdjDemandTypes';

export type FdjOngoingDemandWithSameOutletCodeDialogProps = {
  demand: FdjDemand | null;
  onContinueDemandCreation: () => void;
};

export const FdjOngoingDemandWithSameOutletCodeDialog = ({
  demand,
  onContinueDemandCreation,
}: FdjOngoingDemandWithSameOutletCodeDialogProps) => {
  const { navigateToStaticRoute } = useNavigationHelper();

  const redirectToDemandsListWithPreparedSearchParameters = () => {
    navigateToStaticRoute(getDemandsList(), {
      outletCode: demand!.outlet.code,
    });
  };

  return (
    <Dialog data-testid="ongoing-demand-exists" open={Boolean(demand)}>
      <DialogContent>
        <p>Une demande est déjà ouverte pour ce code détaillant.</p>
        <p>Souhaitez-vous quand même créer cette nouvelle demande ?</p>
      </DialogContent>
      <DialogActions>
        <SecondaryActionButton label="Non" action={redirectToDemandsListWithPreparedSearchParameters} />
        <MainActionButton dataTestId="continue-demand-creation" label="Oui" action={onContinueDemandCreation} />
      </DialogActions>
    </Dialog>
  );
};
