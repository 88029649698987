import { FdjDemandsFiltersType, FdjDemandsLoadRequestParams } from '../demand/fdjDemandListTypes';
import React from 'react';
import { Grid } from '@mui/material';
import FormFiltersListener from '@eogile/agrements-common/src/form/components/FormFiltersListener';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import AgencySelect from '@eogile/agrements-common/src/components/agencies/AgencySelect';
import { computeAgencyName } from '../demand/fdjDemandUtils';
import DateRangePickerAdapter from '@eogile/agrements-common/src/form/components/DateRangePickerAdapter';
import { EnumSelectAdapter } from '@eogile/agrements-common/src/form/components/EnumSelectAdapter';
import { GedRequestStatus } from './gedTypes';
import { gedRequestStatusLabels } from './gedUtils';
import { Form } from 'react-final-form';
import { fdjDemandsFiltersValidator } from '../demand/filters/fdjDemandsFiltersUtils';
import { BooleanSelectAdapter } from '@eogile/agrements-common/src/form/components/BooleanSelectAdapter';
import { CollapsableCard } from '@eogile/agrements-common/src/components/card/CollapsableCard';

type GedSupervisionDemandsFiltersProps = {
  initialValues: FdjDemandsFiltersType;
  formValuesToQueryString: (values: FdjDemandsLoadRequestParams) => Record<string, string>;
};

export const GedSupervisionDemandsFilters = ({
  initialValues,
  formValuesToQueryString,
}: GedSupervisionDemandsFiltersProps) => {
  const defaultOpened = Object.values(initialValues).some(Boolean);
  return (
    <Form initialValues={initialValues} validate={fdjDemandsFiltersValidator} subscription={{}} onSubmit={() => {}}>
      {() => (
        <CollapsableCard defaultOpened={defaultOpened} title="Critères">
          <FormFiltersListener transformValues={formValuesToQueryString} />
          <Grid container rowSpacing={2}>
            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <TextFieldAdapter name="chronoNumber" label="Numéro de dossier" />
              </Grid>
              <Grid item xs={6}>
                <TextFieldAdapter name="outletCode" label="Code détaillant" />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <DateRangePickerAdapter
                  lowerBoundFieldName="verdictValidationDateLowerBound"
                  upperBoundFieldName="verdictValidationDateUpperBound"
                  label="Date de validation de l'avis"
                />
              </Grid>
              <Grid item xs={6}>
                <AgencySelect agencyName={computeAgencyName} disabled={false} fieldLabel="Secteur" />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <BooleanSelectAdapter label="Présence d'un avis signé" name="hasSignedVerdictFile" />
              </Grid>
              <Grid item xs={6}>
                <EnumSelectAdapter
                  label="Statut du dernier envoi"
                  name="gedRequestStatus"
                  options={Object.values(GedRequestStatus)}
                  optionName={(opt) => gedRequestStatusLabels[opt]}
                />
              </Grid>
            </Grid>
          </Grid>
        </CollapsableCard>
      )}
    </Form>
  );
};
