import { useField } from 'react-final-form';

export const useIsIntegratedDemandFormValue = () => {
  const isIntegratedDemandField = useField('integratedDemand.integratedDemand', {
    subscription: { value: true, dirty: true },
  });
  return {
    dirty: isIntegratedDemandField.meta.dirty,
    value: (isIntegratedDemandField.input.value ?? false) as boolean,
  };
};
