import { compatibleAgreementTypesForCaseType } from '../fdjDemandUtils';
import { useFdjCaseTypeFormValue } from './useFdjCaseTypeFormValue';

export const useAgreementTypeFormHelper = () => {
  const { value: caseType } = useFdjCaseTypeFormValue();

  return {
    compatibleAgreementTypes: compatibleAgreementTypesForCaseType(caseType),
  };
};
