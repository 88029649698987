import { FdjUserApiSaveCommand, FdjUserFormValues } from './fdjUserFormTypes';
import { FdjUser, FdjUserProfile } from '../fdjUserTypes';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import { fdjProfileDisplayLabels } from '../fdjUserUtils';
import { caseInsensitiveCompare } from '@eogile/agrements-common/src/utils/stringUtils';

/**
 * Return the form values matching the given user.
 */
export function toFormValues(user?: FdjUser): FdjUserFormValues {
  if (!user) {
    return formValuesForNewUser();
  }
  return {
    id: user.id,
    login: user.login,
    firstName: user.firstName,
    lastName: user.lastName,
    civility: user.civility,
    email: user.email,
    profiles: [...user.profileCodes],
    agencyCode: user.agency?.code,
  };
}

function formValuesForNewUser(): FdjUserFormValues {
  return {
    id: null,
    login: null,
    firstName: null,
    lastName: null,
    civility: null,
    email: null,
    profiles: [],
    agencyCode: null,
  };
}

/**
 * Transform the valid form values to API request's payload.
 */
export function toApiSaveCommand(formValues: FdjUserFormValues): FdjUserApiSaveCommand {
  return {
    login: formValues.login!,
    company: UserCompany.FDJ,
    lastName: formValues.lastName!,
    firstName: formValues.firstName!,
    civility: formValues.civility!,
    email: formValues.email!,
    profiles: [...formValues.profiles],
    agencyCode: formValues.agencyCode,
  };
}

/**
 * Return the FDJ profiles that cannot be selected.
 *
 * Currently selected profiles are always present in the response.
 */
export function computeUnselectableProfiles(alreadySelectedProfiles: FdjUserProfile[]) {
  const profiles = [];
  if (alreadySelectedProfiles.includes(FdjUserProfile.FDJ_AGENCY)) {
    profiles.push(FdjUserProfile.FDJ_DEMAND_READER);
    profiles.push(FdjUserProfile.FDJ_DEMAND_WRITER);
  }

  if (alreadySelectedProfiles.includes(FdjUserProfile.FDJ_DEMAND_WRITER)) {
    profiles.push(FdjUserProfile.FDJ_AGENCY);
  }

  if (alreadySelectedProfiles.includes(FdjUserProfile.FDJ_DEMAND_READER)) {
    profiles.push(FdjUserProfile.FDJ_AGENCY);
  }

  return profiles.filter((p) => !alreadySelectedProfiles.includes(p));
}

/**
 * The profile codes sorted by profile names.
 */
export const sortedProfileCodes = Object.values(FdjUserProfile).sort((p1, p2) => {
  return caseInsensitiveCompare(fdjProfileDisplayLabels[p1], fdjProfileDisplayLabels[p2]);
});
