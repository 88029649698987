import { ZonedDateTime } from '@eogile/agrements-common/src/common-types/dateTypes';

export enum GedRequestStatus {
  OK = 'OK',
  ERROR = 'ERROR',
}

export type GedRequestTraceView = Readonly<{
  id: string;
  status: GedRequestStatus;
  sentDateTime: ZonedDateTime;
  statusCode: number | null;
  errorDetail: string | null;
}>;

export type GedSignedVerdictFileState = Readonly<{
  status: GedRequestStatus;
  sentDateTime: ZonedDateTime;
  attempts: number;
}>;
