import { useField, useForm } from 'react-final-form';
import { useEffect } from 'react';
import { useStandardCancelledVerdictFormHelper } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/useStandardCancelledVerdictFormHelper';
import { CancellationReason, VerdictType } from '@eogile/agrements-common/src/demand/demandTypes';
import { useVerdictTypeFormValue } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/useVerdictTypeFormValue';
import { FdjDemand } from '../../../fdjDemandTypes';
import { fdjMinistryCancellationReasons } from './fdjVerdictInputFormUtils';

type FdjCancelledVerdictFormHelperHook = {
  supportsMinistryCancellationFields: boolean;
  supportsCancellationCommentField: boolean;
  activeCancellationReasons: readonly CancellationReason[];
};

export const useFdjCancelledVerdictFormHelper = (demand: FdjDemand): FdjCancelledVerdictFormHelperHook => {
  const { supportsMinistryCancellationFields } = useStandardCancelledVerdictFormHelper(demand);
  const { change } = useForm();
  const currentVerdictType = useVerdictTypeFormValue().value;
  const cancellationReasonField = useField('cancellationReason', { subscription: { value: true } });
  const currentCancellationReason = cancellationReasonField.input.value;
  const supportsCancellationCommentField =
    supportsMinistryCancellationFields && currentCancellationReason === CancellationReason.MINISTRY_OTHER;

  useEffect(() => {
    if (currentVerdictType !== VerdictType.CANCELLED || !supportsMinistryCancellationFields) {
      change('cancellationReason', undefined);
    }
  }, [change, currentVerdictType, supportsMinistryCancellationFields]);

  useEffect(() => {
    // note that we already reset this value when currentVerdictType changes in 'useStandardCancelledVerdictFormHelper'
    if (!supportsCancellationCommentField) {
      change('cancellationComment', undefined);
    }
  }, [change, supportsCancellationCommentField]);

  useEffect(() => {
    if (currentCancellationReason && !fdjMinistryCancellationReasons.includes(currentCancellationReason)) {
      change('cancellationReason', undefined);
    }
  });

  return {
    supportsMinistryCancellationFields,
    supportsCancellationCommentField,
    activeCancellationReasons: fdjMinistryCancellationReasons,
  };
};
