import React from 'react';
import { Comment, FdjDemand } from '../../../fdjDemandTypes';
import { CurrentFdjApplicationUserDetails } from '../../../../security/types';
import useMarkAsRead from './internal/useMarkAsRead';

type ReturnType = {
  comments: readonly Comment[];
  ref: React.MutableRefObject<any>;
};

function useComments(
  demand: FdjDemand,
  currentUserDetails: CurrentFdjApplicationUserDetails,
  onDemandRefresh: (demand: FdjDemand) => void,
): ReturnType {
  return {
    comments: demand.comments,
    ref: useMarkAsRead(demand, currentUserDetails, onDemandRefresh),
  };
}

export default useComments;
