import { CancellationReason } from '../../../demandTypes';
import { EnumSelectAdapter } from '../../../../form/components/EnumSelectAdapter';
import { cancellationReasonLabels } from '../../../demandUtils';

export const CancellationReasonSelectAdapter = ({
  availableCancellationReasons,
}: {
  availableCancellationReasons: readonly CancellationReason[];
}) => (
  <EnumSelectAdapter<CancellationReason>
    label="Motif"
    name="cancellationReason"
    required
    options={availableCancellationReasons as CancellationReason[]}
    optionName={(v) => cancellationReasonLabels[v]}
  />
);
