import { FdjDemand } from '../../../../fdjDemandTypes';
import { CurrentMinistryUserDetails, Permission } from '@eogile/agrements-common/src/security/types';
import { isFdjUser } from '../../../../../user/fdjUserUtils';
import { CurrentFdjApplicationUserDetails, CurrentFdjUserDetails } from '../../../../../security/types';
import { isMinistryUser } from '@eogile/agrements-common/src/user/userUtils';

/**
 * Return a boolean indicating whether we should trigger an HTTP
 * request to mark at least one comment as read.
 */
export function shouldMarkCommentsAsRead(
  demand: FdjDemand,
  currentUserDetails: CurrentFdjApplicationUserDetails,
): boolean {
  if (!currentUserDetails.permissions.includes(Permission.MARK_COMMENT_AS_READ)) {
    return false;
  }
  if (!hasAnyUnreadComments(demand, currentUserDetails)) {
    return false;
  }
  if (isFdjUser(currentUserDetails)) {
    return sameAgency(demand, currentUserDetails);
  }
  if (isMinistryUser(currentUserDetails)) {
    return sameTerritorialAreaOrNoUserTerritorialArea(demand, currentUserDetails);
  }
  throw new Error('We should never go there');
}

/**
 * Return a boolean indicating whether there are some unread comments added
 * by a different company.
 *
 * Comments added by the current user's company are not relevant because users
 * cannot mark as read comments of their own company.
 */
function hasAnyUnreadComments(demand: FdjDemand, currentUserDetails: CurrentFdjApplicationUserDetails): boolean {
  return demand.comments.some(
    (comment) => comment.status === 'UNREAD' && comment.authorCompany !== currentUserDetails.company,
  );
}

/**
 * Return a boolean indicating the user and the demand are associated with the same agency.
 */
function sameAgency(demand: FdjDemand, currentUserDetails: CurrentFdjUserDetails): boolean {
  return demand.agency.code === currentUserDetails.agency?.code;
}

/**
 * Return a boolean indicating whether the ministry user has no territorial area
 * or the same one as the demand.
 */
function sameTerritorialAreaOrNoUserTerritorialArea(
  demand: FdjDemand,
  currentUserDetails: CurrentMinistryUserDetails,
): boolean {
  return (
    !Boolean(currentUserDetails.territorialArea) ||
    demand.ministryTerritorialArea === currentUserDetails.territorialArea
  );
}
