import { SectionCard } from '@eogile/agrements-common/src/components/cards/SectionCard';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { FormMode, useFormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import { FormGridItem } from '@eogile/agrements-common/src/components/grid/FormGridItem';
import { useIsLightDemandFormHelper } from '@eogile/agrements-common/src/demand/hooks/useIsLightDemandFormHelper';
import { EnumSelectAdapter } from '@eogile/agrements-common/src/form/components/EnumSelectAdapter';
import { Civility } from '@eogile/agrements-common/src/common-types/civilityTypes';
import { fdjCandidateTypeLabels } from '../fdjDemandUtils';
import { fdjCivilityLabels } from '../../utils/fdjLabelUtils';
import { DemandCandidateAddress } from '@eogile/agrements-common/src/demand/components/candidate/DemandCandidateAddress';
import { DemandCandidateContactFields } from '@eogile/agrements-common/src/demand/components/candidate/DemandCandidateContactFields';
import { DemandCandidateCommonIdentityFields } from '@eogile/agrements-common/src/demand/components/candidate/DemandCandidateCommonIdentityFields';
import { withFieldNamePrefix } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { FormGridContainer } from '@eogile/agrements-common/src/components/grid/FormGridContainer';
import { FormGridContainersStack } from '@eogile/agrements-common/src/components/grid/FormGridContainersStack';
import { useForm } from 'react-final-form';
import { useAcquirerTypeFormValue } from '@eogile/agrements-common/src/demand/hooks/useAcquirerTypeFormValue';
import { AcquirerType } from '@eogile/agrements-common/src/demand/demandTypes';
import { fdjCandidateTypesCompatibleWith } from '../fdjDemandFormUtils';
import { RequesterLinkedDemands } from '@eogile/agrements-common/src/demand/components/candidate/RequesterLinkedDemands';
import { FdjDemand } from '../fdjDemandTypes';

type Props = {
  name: string;
  index: number;
  demand: FdjDemand | undefined;
};

export const FdjDemandCandidateSection = ({ name, index, demand }: Props) => {
  const { mutators } = useForm();
  const mode = useFormMode();
  const isLightDemand = useIsLightDemandFormHelper();
  const deletable = mode !== FormMode.READ && index > 0;
  const fieldName = withFieldNamePrefix(name);
  const acquirerType = useAcquirerTypeFormValue();

  const cardTitle = index === 0 ? 'Représentant légal' : `Associé ${index}`;

  return (
    <SectionCard
      title={cardTitle}
      headerAction={
        deletable ? (
          <IconButton onClick={() => mutators.remove('candidates', index)}>
            <Delete />
          </IconButton>
        ) : undefined
      }
    >
      <FormGridContainersStack>
        <FormGridContainer>
          {!isLightDemand && (
            <>
              <FormGridItem>
                <EnumSelectAdapter
                  label="Type"
                  name={fieldName('type')}
                  required
                  disabled={acquirerType === AcquirerType.IN_OWN_NAME}
                  options={fdjCandidateTypesCompatibleWith(acquirerType)}
                  optionName={(opt) => fdjCandidateTypeLabels[opt]}
                />
              </FormGridItem>
            </>
          )}
          <FormGridItem>
            <EnumSelectAdapter
              label="Civilité"
              name={fieldName('civility')}
              required
              options={Object.values(Civility)}
              optionName={(opt) => fdjCivilityLabels[opt]}
            />
          </FormGridItem>
          <DemandCandidateCommonIdentityFields name={name} />
        </FormGridContainer>
        {!isLightDemand && (
          <>
            <DemandCandidateAddress name={name} />
            <DemandCandidateContactFields name={name} />
          </>
        )}
      </FormGridContainersStack>
      {mode === FormMode.READ && (
        <RequesterLinkedDemands
          demandId={demand!.id}
          requesterIndex={index}
          requesterSearchCriteria={{
            firstName: demand!.candidates[index].firstNames,
            lastName: demand!.candidates[index].lastName,
            birthDate: demand!.candidates[index].birthDate,
          }}
        />
      )}
    </SectionCard>
  );
};
