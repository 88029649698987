import SelectAdapter, { Option, SelectAdapterProps } from './SelectAdapter';
import React from 'react';

export type BooleanSelectChoice = 'true' | 'false';

type BooleanSelectAdapterProps = Omit<SelectAdapterProps<BooleanSelectChoice>, 'options'>;

export const BooleanSelectAdapter = (props: BooleanSelectAdapterProps) => {
  const selectOptions: Option<BooleanSelectChoice>[] = [
    { id: 'true', name: 'Oui' },
    { id: 'false', name: 'Non' },
  ];
  return <SelectAdapter options={selectOptions} {...props} />;
};
