import { FdjCaseType, FdjRootDemandView, isFdjFullRootDemandView } from '../fdjDemandTypes';
import { useFdjCaseTypeFormValue } from './useFdjCaseTypeFormValue';
import { useIsIntegratedDemandFormValue } from './useIsIntegratedDemandFormValue';
import { useField, useForm } from 'react-final-form';
import { prefillRequestHiddenFieldName, toLightCandidateFormValues } from '../fdjDemandFormUtils';
import FdjDemandFormApiUtils from '../fdjDemandFormApiUtils';
import { toAcquirerFormValues, toCandidateFormValues } from '@eogile/agrements-common/src/demand/demandFormUtils';

type DemandPrefillRequest = 'WAITING' | 'SUCCESS' | 'FAILURE' | undefined;

export const useIntegratedDemandFormHelper = () => {
  // "hidden" field to track the prefill request status. This is needed because:
  // - we want to trigger the AJAX call when the field is blurred and not on every keystroke
  // - this mechanism is part of the form validation (display error, prevent form submission on invalid request)
  // - needs to be aware of the Final-Form context (we need a way to manually re-trigger the validation,
  //   we use 'change' for that, a 'useRef' tracking the request status in the validate function would not be enough
  //   because it wouldn't re-trigger the validation when the response is received)
  const { meta } = useField(prefillRequestHiddenFieldName, {
    validate: (v: DemandPrefillRequest) => {
      if (v === 'FAILURE') {
        return 'Le numéro de chrono ne correspond à aucune demande existante ayant reçu un avis favorable';
      } else if (v === 'WAITING') {
        // "fake" error to prevent form submission while we're trying to fetch the demand
        return 'WAITING';
      }
      return undefined;
    },
  });
  const { getFieldState, change } = useForm();
  const { value } = useFdjCaseTypeFormValue();
  const isIntegratedDemand = useIsIntegratedDemandFormValue().value;
  const isCreationCaseType = value === FdjCaseType.CREATION;

  const changePrefillRequestStatus = (status: DemandPrefillRequest) => {
    change(prefillRequestHiddenFieldName, status);
  };

  // always returns the most up-to-date value
  const getCurrentRootDemandChronoNumber = (): string | undefined =>
    getFieldState('integratedDemand.rootDemandChronoNumber')?.value ?? undefined;

  // a response can be rejected, here are some possible reasons:
  // - the user changed the field value while waiting for the server response
  // - the user unchecked the 'integrated demand' checkbox or performed other actions that removed the field value
  // while waiting for the server response
  const shouldAcceptPrefillResponse = (requestDemandChronoNumber: string) =>
    getCurrentRootDemandChronoNumber() === requestDemandChronoNumber;

  const requestPrefill = async () => {
    changePrefillRequestStatus('WAITING');
    const requestDemandChronoNumber = getCurrentRootDemandChronoNumber();
    if (requestDemandChronoNumber) {
      try {
        const foundDemand =
          await FdjDemandFormApiUtils.findDemandWithGrantedVerdictByChronoNumber(requestDemandChronoNumber);
        if (!shouldAcceptPrefillResponse(requestDemandChronoNumber)) {
          // skip this prefill request because another one has been requested while waiting for the server to respond
          return;
        }
        prefillWith(foundDemand);
        changePrefillRequestStatus('SUCCESS');
      } catch (e) {
        changePrefillRequestStatus('FAILURE');
      }
    } else {
      changePrefillRequestStatus(undefined);
    }
  };

  const prefillWith = (demand: FdjRootDemandView) => {
    if (isFdjFullRootDemandView(demand)) {
      change('acquirer', { ...toAcquirerFormValues(demand), eirl: undefined });
      change('candidates', demand.candidates.map(toCandidateFormValues));
    } else {
      change('acquirer', undefined);
      change('candidates', demand.candidates.map(toLightCandidateFormValues));
    }
  };

  return {
    isCreationCaseType,
    isIntegratedDemand,
    requestPrefill,
    prefillError: meta.error === 'WAITING' ? undefined : meta.error,
  };
};
