import { useEffect, useRef } from 'react';

export function usePrevious<T>(value: T | undefined) {
  // initialize the ref with the initial 'value': if not null or undefined, this means that on the first render,
  // the "previous value" will be equal to the current value, which is what we want so far on this project
  // (we use this hook to track form field changes
  // in a similar way as https://github.com/final-form/react-final-form-listeners/blob/master/src/OnChange.js)
  const ref = useRef<T | undefined>(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
