import React from 'react';
import { FdjUser } from '../../fdjUserTypes';
import { TableCell } from '@mui/material';
import UserLoginTableCell from '@eogile/agrements-common/src/user/list/components/UserLoginTableCell';
import UserLastNameTableCell from '@eogile/agrements-common/src/user/list/components/UserLastNameTableCell';
import UserFirstNameTableCell from '@eogile/agrements-common/src/user/list/components/UserFirstNameTableCell';
import UserEmailTableCell from '@eogile/agrements-common/src/user/list/components/UserEmailTableCell';
import { displayAgencyName } from '../../../demand/fdjDemandUtils';
import UserProfilesTableCell from '@eogile/agrements-common/src/user/list/components/UserProfilesTableCell';
import { fdjProfileDisplayLabels } from '../../fdjUserUtils';
import UserUpdatedAtTableCell from '@eogile/agrements-common/src/user/list/components/UserUpdatedAtTableCell';
import UserTableRow from '@eogile/agrements-common/src/user/list/components/UserTableRow';
import DeleteUserAction from '@eogile/agrements-common/src/user/list/components/DeleteUserAction';

type Props = {
  user: FdjUser;
  reloadUsers: () => Promise<void>;
};

function agencyCellContent(user: FdjUser) {
  if (user.agency) {
    return displayAgencyName(user.agency.code, user.agency.name);
  }
  return null;
}

const FdjUsersTableRow = ({ user, reloadUsers }: Props) => {
  return (
    <UserTableRow userId={user.id}>
      <UserLoginTableCell user={user} />
      <UserLastNameTableCell user={user} />
      <UserFirstNameTableCell user={user} />
      <UserEmailTableCell user={user} />
      <TableCell>{agencyCellContent(user)}</TableCell>
      <UserProfilesTableCell
        profileCodes={user.profileCodes}
        getProfileName={(profileCode) => fdjProfileDisplayLabels[profileCode]}
      />
      <UserUpdatedAtTableCell user={user} />
      <TableCell>
        <DeleteUserAction user={user} reloadUsers={reloadUsers} />
      </TableCell>
    </UserTableRow>
  );
};

export default FdjUsersTableRow;
