import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { FdjDemandsSortableFields } from '../demand/fdjDemandListTypes';
import { TableHead, TableRow } from '@mui/material';
import SortableTableCell from '@eogile/agrements-common/src/components/table/SortableTableCell';
import TableCell from '@eogile/agrements-common/src/components/table/TableCell';
import React from 'react';

type GedSupervisionDemandsTableHeaderProps = {
  onCommandChange: (newCommand: PaginationAndSortCommand<FdjDemandsSortableFields>) => void;
  command: PaginationAndSortCommand<FdjDemandsSortableFields>;
};

export const GedSupervisionDemandsTableHeader = ({
  onCommandChange,
  command,
}: GedSupervisionDemandsTableHeaderProps) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableCell<FdjDemandsSortableFields>
          label="No dossier"
          sortField="CHRONO_NUMBER"
          onChange={onCommandChange}
          command={command}
        />
        <SortableTableCell<FdjDemandsSortableFields>
          label="Code détaillant"
          sortField="OUTLET_CODE"
          onChange={onCommandChange}
          command={command}
        />
        <SortableTableCell<FdjDemandsSortableFields>
          label="Date de validation de l'avis"
          sortField="VERDICT_VALIDATION_DATE_TIME"
          onChange={onCommandChange}
          command={command}
        />
        <SortableTableCell<FdjDemandsSortableFields>
          label="Secteur"
          sortField="AGENCY"
          onChange={onCommandChange}
          command={command}
        />
        <TableCell>Avis signé présent</TableCell>
        <TableCell>Statut du dernier envoi</TableCell>
        <SortableTableCell<FdjDemandsSortableFields>
          label="Date et heure du dernier envoi"
          sortField="GED_SENT_DATE_TIME"
          onChange={onCommandChange}
          command={command}
        />
      </TableRow>
    </TableHead>
  );
};
