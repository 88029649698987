import { usePageTitle } from '@eogile/agrements-common/src/layout/usePageTitle';
import { useAuthenticatedCurrentUser } from '@eogile/agrements-common/src/security/CurrentUserContext';
import { Permission } from '@eogile/agrements-common/src/security/types';
import ForbiddenCard from '@eogile/agrements-common/src/components/cards/ForbiddenCard';
import React from 'react';
import { GedSupervisionDemandsList } from './GedSupervisionDemandsList';

export const GedSupervisionPage = () => {
  const currentUser = useAuthenticatedCurrentUser();
  const hasPermission = currentUser.userDetails.permissions.includes(Permission.SIGNED_VERDICT_SUPERVISION);
  usePageTitle(hasPermission ? "Supervision de l'envoi des avis signés à la GED" : '');

  if (!hasPermission) {
    return <ForbiddenCard />;
  }
  return <GedSupervisionDemandsList />;
};
