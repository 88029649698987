// noinspection JSUnusedGlobalSymbols

export const FdjDemandFormTooltipContents = {
  OutletCode: () => (
    <>
      Code reçu dans EGIDE après approbation du profil par la FDJ. Ce code est l’identifiant unique du représentant
      légal du point de vente. Celui-ci est composé uniquement de chiffres
    </>
  ),
  ReceivedGrantedVerdictInPast12Months: () => (
    <>
      <p>
        Si le client pro a eu un accord favorable de la part du SCCJ dans les 12 derniers mois, 2 cas s'offrent à vous.
      </p>
      <p>
        Si cela concerne un type d'agrément FDJ, saisir le N° de dossier SCCJ relatif à l'accord favorable obtenu en le
        cherchant dans le dossier d'agrément ou dans cette application.
      </p>
      <p>
        Si cet accord concernait l'agrément PMU alors saisir le N° de dossier SCCJ obtenu. Si le client pro n'est pas en
        possession du N° de dossier du précédent accord, cocher uniquement la case et ne pas remplir le N° Chrono de
        l'ancienne demande.
      </p>
    </>
  ),
  OutletName: () => (
    <>
      Fournir le nom du Point de vente disponible sur l’extrait KBIS sous la dénomination « Nom commercial » ou «
      Enseigne ». Si celui-ci n’existe pas, donner le nom de la société exploitante précédé de son statut juridique. (Ex
      : SARL TABAC ET CIE). Dans le cas d’une cession, il faut renseigner le nom de l’enseigne ou à défaut le nom de la
      personne physique du repreneur.
    </>
  ),
  OutletOperatingMode: () => (
    <>L’information est disponible sur le KBIS ou extrait K sous la dénomination « Mode d’exploitation »</>
  ),
  OutletAcquisitionDate: () => (
    <>
      La date d’acquisition du fonds de commerce est disponible sur le KBIS ou extrait K sous la dénomination « Date de
      commencement de l’activité ». Dans le cas d’une cession, entrer la date prévisionnelle de signature de l’acte de
      vente.
    </>
  ),
  OutletAddress: () => (
    <>
      L’adresse du PDV correspond à l’adresse du PDV qui est concerné par la demande SCCJ. Cette adresse peut être
      trouvée sur le KBIS sous la dénomination « Adresse de l’établissement »
    </>
  ),
  AcquirerType: () => (
    <>
      <p>
        L’option en nom propre correspond aux entreprises individuelles. L’option en société s’applique lorsqu’une
        société exploite l’activité.
      </p>
      <p>
        Vous pourrez faire la distinction grâce au KBIS. Pour les entreprises en nom propre une rubrique est intitulée «
        Identification de la personne Physique » alors qu’elle est intitulée « Identification de la personne Morale »
        pour les sociétés
      </p>
    </>
  ),
  AcquirerCompanyType: () => (
    <>Le type de société est disponible sur l’extrait KBIS sous la dénomination « Forme juridique »</>
  ),
};
